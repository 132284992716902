/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState } from 'react';
import '../../index.css';
import AccordionButtonElement from '../elements/AccordionButtonElement';
import { NAV_ITEMS } from '../../api';

interface AccordionProps {
  isOpenNav: boolean;
  onOpen?: (c: boolean) => void;
}
const Accordion: React.FC<AccordionProps> = ({ isOpenNav, onOpen }) => {
  const [activeIndex, setActiveIndex] = useState(null);

  const handleItemClick = (index: any) => {
    setActiveIndex((prevIndex) => (prevIndex === index ? null : index));
  };

  return (
    <>
      {NAV_ITEMS.map((item, index) => {
        return (
          <ul key={index} className="nav pcoded-inner-navbar relative">
            <AccordionButtonElement
              item={item}
              index={index}
              isOpenNav={isOpenNav}
              onOpen={onOpen}
              onClick={() => handleItemClick(index)}
              isShow={activeIndex === index}
            />
          </ul>
        );
      })}
    </>
  );
};
export default Accordion;
