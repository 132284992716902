import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { loadStripe } from '@stripe/stripe-js';

import { AuthProvider } from './providers/auth/AuthProvider';
import { useAuthContext } from './providers/auth/hooks/useAuthContext';
import { MetaPixel } from './components/common';
import DashboardLayoyt from './components/dashboard/DashboardLayoyt';
import AddPassword from './pages/auth/AddPassword';
import LoginCodePasswordRestore from './pages/auth/LoginCodePasswordRestore';
import LoginCodeSignIn from './pages/auth/LoginCodeSignIn';
import LoginCodeSignUp from './pages/auth/LoginCodeSignUp';
import LoginEmail from './pages/auth/LoginEmail';
import LoginEmailPasswordRestore from './pages/auth/LoginEmailPasswordRestore';
import LoginPassword from './pages/auth/LoginPassword';
import PasswordRestoreAddPassword from './pages/auth/PasswordRestore';
import SignUp from './pages/auth/SignUp';
import PaymentMethodPage from './pages/billing/payment-method/PaymentMethodPage';
import CampaignDetails from './pages/dashboard/CampaignDetails';
import Audience from './pages/dashboard/Audience';
import Billing from './pages/dashboard/Billing';
import CreateCampaign from './pages/dashboard/CreateCampaign';
import Campaigns from './pages/dashboard/Campaigns';
import Dashboard from './pages/dashboard/Dashboard';
import EditCampaignDetails from './pages/dashboard/EditCampaignDetails';
import OrganizationCreatePage from './pages/organization/OrganizationCreatePage/OrganizationCreatePage';
import OrganizationDetails from './pages/dashboard/OrganizationDetails';
import Error404 from './pages/errors/Error404';
import SomethingWrong from './pages/errors/SomethingWrong';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY ?? '');

const Main = () => {
  const { isAuthorized } = useAuthContext();
  return (
    <Router>
      <MetaPixel />
      <Routes>
        {isAuthorized ? (
          <Route path="/" element={<DashboardLayoyt children={<Dashboard />} />} />
        ) : (
          <Route path="/" element={<LoginEmail />} />
        )}
        {isAuthorized ? null : <Route path="/login-email" element={<LoginEmail />} />}
        <Route path="/add-password" element={<AddPassword />} />
        {isAuthorized ? <Route path="*" element={<LoginEmail />} /> : <Route path="*" element={<Error404 />} />}
        {isAuthorized ? <Route path="/error" element={<Error404 />} /> : null}
        <Route path="/login-password" element={<LoginPassword />} />
        <Route path="/login-email-password-restore" element={<LoginEmailPasswordRestore />} />
        <Route path="/add-password-restore" element={<PasswordRestoreAddPassword />} />
        {isAuthorized ? null : <Route path="/login-password" element={<LoginPassword />} />}
        {isAuthorized ? null : <Route path="/login-code-sign-up" element={<LoginCodeSignUp />} />}
        {isAuthorized ? null : <Route path="/login-code-sign-in" element={<LoginCodeSignIn />} />}
        {isAuthorized ? null : <Route path="/login-code-password-restore" element={<LoginCodePasswordRestore />} />}
        {isAuthorized ? <Route path="/dashboard" element={<DashboardLayoyt children={<Dashboard />} />} /> : null}
        {isAuthorized ? <Route path="/audience-list" element={<DashboardLayoyt children={<Audience />} />} /> : null}
        {isAuthorized ? <Route path="/campaigns" element={<DashboardLayoyt children={<Campaigns />} />} /> : null}
        {isAuthorized ? <Route path="/campaign/new" element={<DashboardLayoyt children={<CreateCampaign />} />} /> : null}
        {isAuthorized ? (
          <Route path="/campaign/:id" element={<DashboardLayoyt children={<CampaignDetails />} />} />
        ) : null}
        {isAuthorized ? (
          <Route path="/campaign/:id/edit" element={<DashboardLayoyt children={<EditCampaignDetails />} />} />
        ) : null}
        {isAuthorized ? (
          <Route path="/billing/payment-method/paddle" element={<DashboardLayoyt children={<Billing />} />} />
        ) : null}
        {isAuthorized ? (
          <Route
            path="/billing/payment-method"
            element={<DashboardLayoyt children={<PaymentMethodPage stripePromise={stripePromise} />} />}
          />
        ) : null}
        {isAuthorized ? (
          <Route path="/organization/create" element={<DashboardLayoyt children={<OrganizationCreatePage />} />} />
        ) : null}
        {isAuthorized ? (
          <Route
            path="/organization-details/:id"
            element={<DashboardLayoyt children={<OrganizationDetails />} />}
          />
        ) : null}
        {isAuthorized ? (
          <Route path="/wrong-request" element={<DashboardLayoyt children={<SomethingWrong />} />} />
        ) : null}
        {isAuthorized ? null : <Route path="/sign-up" element={<SignUp />} />}

        {isAuthorized ? (
          <Route
            path="/billing/payment-method"
            element={<DashboardLayoyt children={<PaymentMethodPage stripePromise={stripePromise} />} />}
          />
        ) : null}
      </Routes>
    </Router>
  );
};
function App() {
  return (
    <AuthProvider>
      <Main />
    </AuthProvider>
  );
}

export default App;
