/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useCallback, useState } from 'react';
import '../../index.css';
import FeatherIcon from 'feather-icons-react';
import { useTranslation } from 'react-i18next';
import Collapsible from 'react-collapsible';

import { NavLink, useMatch, useResolvedPath } from 'react-router-dom';

const CustomNavLink: React.FC<{
  to?: string;
  title: string;
  onClick?: () => void;
  icon?: string;
  chevron?: boolean;
  rotate?: boolean;
  inner?: boolean;
}> = ({ to, title, onClick, icon, chevron, rotate, inner }) => {
  const resolved = useResolvedPath(to as string);
  const match = useMatch({ path: resolved.pathname, end: true });
  const { t } = useTranslation();

  return (
    <NavLink onClick={onClick} to={to as string} className={`${match ? 'nav-item active' : 'nav-item'}`}>
      {!inner ? (
        <span className={`${match ? 'home-link active' : 'home-link'}`} onClick={onClick}>
          <span className="pcoded-micon">
            <FeatherIcon icon={icon as string} size={16} />
          </span>
          <span className="pcoded-mtext">{t(title)}</span>
          {chevron ? (
            <FeatherIcon className={rotate ? 'chevron rotate' : 'chevron'} icon="chevron-right" size={16} />
          ) : null}
        </span>
      ) : (
        <ul className={`${match ? 'pcoded-submenu active' : 'pcoded-submenu'}`} onClick={onClick}>
          <li className="d-flex align-items-center">
            <span className={`${match ? 'hidden active' : 'hidden'}`} />
            <span className="span-link">
              {t(title)} {icon ? <FeatherIcon className="pcoded-micon" icon="external-link" size={16} /> : null}
            </span>
          </li>
        </ul>
      )}
    </NavLink>
  );
};

type AccordionButtonElementProps = {
  item: any;
  index: number;
  isOpenNav: boolean;
  isShow?: boolean;
  onOpen?: (c: boolean) => void;
  onClick?: (i: number) => void;
};

const AccordionButtonElement: React.FC<AccordionButtonElementProps> = ({
  item,
  index,
  isOpenNav,
  onOpen,
  onClick,
  isShow,
}) => {
  const [isOpen, setIsOpen] = useState(isOpenNav);

  const showItem = useCallback(
    (i: number) => {
      if (onClick) {
        onClick(i);
      }
    },
    [onClick]
  );

  const onClose = useCallback(() => {
    setIsOpen(!isOpen);
    if (onOpen && isOpen !== undefined) {
      onOpen(!isOpenNav);
    }
  }, [onOpen, isOpen, isOpenNav]);

  const { t } = useTranslation();

  const handleClick = useCallback(() => {
    onClose();
  }, [onClose]);

  const handleHome = useCallback(
    (i: number) => {
      if (onClick) {
        onClick(i);
      }

      onClose();
    },
    [onClose, onClick]
  );

  return (
    <React.Fragment>
      {item.clearHome ? (
        <span className="router-link-active router-link-exact-active">
          <CustomNavLink icon={item.icon} title={item.title} to="/dashboard" onClick={() => handleHome(index)} />
        </span>
      ) : item.audience ? (
        <span className={`${index === 1 ? 'router-link-active router-link-exact-active' : 'nav-item pcoded-hasmenu'}`}>
          <CustomNavLink icon={item.icon} title={item.title} to="/audience-list" onClick={() => handleHome(index)} />
        </span>
      ) : item.campaigns ? (
        <span className={`${index === 2 ? 'router-link-active router-link-exact-active' : 'nav-item pcoded-hasmenu'}`}>
          <CustomNavLink icon={item.icon} title={item.title} to="/campaigns" onClick={() => handleHome(index)} />
        </span>
      ) : item.clear ? (
        <li className="nav-item pcoded-hasmenu">
          <CustomNavLink icon={item.icon} title={item.title} />
        </li>
      ) : (
        <React.Fragment>
          <Collapsible
            trigger={
              <li className="nav-item pcoded-hasmenu" onClick={() => showItem(index)}>
                <span className="home-link">
                  <span className="pcoded-micon">
                    <FeatherIcon icon={item.icon} size={16} />
                  </span>
                  <span className="pcoded-mtext">{t(item.title)}</span>
                  <FeatherIcon className={isShow ? 'chevron rotate' : 'chevron'} icon="chevron-right" size={16} />
                </span>
              </li>
            }
            open={isShow}
          >
            {item.items
              ? item.items.map((item: any, i: number) => {
                  return item.outer ? (
                    <div key={i}>
                      <div className="nav-border" />
                      <ul className="pcoded-submenu">
                        <li className="d-flex align-items-center">
                          <span className="hidden" />
                          <a href={item.href} target="_blank">
                            {t(item.text)}{' '}
                            {item.micon ? (
                              <FeatherIcon className="pcoded-micon" icon="external-link" size={16} />
                            ) : null}
                          </a>
                        </li>
                      </ul>
                    </div>
                  ) : (
                    <div key={i}>
                      <div className="nav-border" />
                      <CustomNavLink icon={item.icon} title={item.text} onClick={handleClick} to={item.href} inner />
                    </div>
                  );
                })
              : null}
          </Collapsible>
        </React.Fragment>
      )}
    </React.Fragment>
  );
};

export default AccordionButtonElement;
