export const NAV_ITEMS = [
  {
    icon: 'home',
    title: 'home',
    clearHome: true,
    href: '/dashboard',
  },
  {
    icon: 'users',
    title: 'audience',
    audience: true,
    href: '/audience-list',
  },
  {
    icon: 'menu',
    title: 'campaigns',
    campaigns: true,
    href: '/campaigns',
  },
  {
    icon: 'credit-card',
    title: 'billing',
    items: [
      {
        href: '/billing/payment-method',
        text: 'paymentMethodTitle',
      },
      // {
      //   href: '/billing/paddle',
      //   text: 'paymentMethodTitle',
      // },
    ],
  },
];
