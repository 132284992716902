import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { StripeElementsOptions } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import FeatherIcon from 'feather-icons-react';

import { useAuthContext } from '../../../providers/auth/hooks/useAuthContext';
import { getPaymentMethods, deletePaymentMethod } from '../../../api';
import { PaymentMethodForm } from './PaymentMethodForm';
import { BankCard } from '../../../components/common/BankCard/BankCard';
import { mapPaymentCards } from '../../../lib/mappers';
import { ConfirmModal } from '../../../components/common/ConfirmModal/ConfirmModal';
import { IPaymentCard } from '../../../types';
import { firebaseAddPaymentInfoEvent } from '../../../lib/analytics/firebaseEvents';

interface IProps {
  stripePromise: Promise<any>;
}

const PaymentMethodPage: FC<IProps> = (props) => {
  const { stripePromise } = props;
  const { t } = useTranslation();
  const { token } = useAuthContext();
  const [customerId, setCustomerId] = useState('');
  const [paymentCards, setPaymentCards] = useState<IPaymentCard[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [selectedCardId, setSelectedCardId] = useState<string>('');
  const [isCardDeleting, setIsCardDeleting] = useState<boolean>(false);
  const [canEdit, setCanEdit] = useState<boolean>(false);
  const [editMode, setEditMode] = useState<boolean>(false);

  const [isOpen, setIsOpen] = useState<boolean>(false);

  useEffect(() => {
    if (token) {
      fetchPaymentMethods();
    }
  }, [token]);

  const fetchPaymentMethods = async () => {
    if (!token) return;

    try {
      setIsLoading(true);
      const paymentMethodsRes = await getPaymentMethods(token);
      if (paymentMethodsRes.customer_id) {
        setCanEdit(paymentMethodsRes.editable);
        setCustomerId(paymentMethodsRes.customer_id);
        setPaymentCards(mapPaymentCards(paymentMethodsRes.items));
      }
    } catch (e) {
      console.log(e);
    } finally {
      setIsLoading(false);
    }
  };

  const onConfirmDeleteHandler = (id: string) => {
    setIsOpen(true);
    setSelectedCardId(id);
  };

  const onDeleteCard = async (paymentMethodId: string) => {
    if (!token) return;

    try {
      setIsCardDeleting(true);
      const response = await deletePaymentMethod(token, paymentMethodId);
      if ('items' in response) {
        setPaymentCards(mapPaymentCards(response.items));
        setIsOpen(false);
      }
    } catch (e) {
      console.log(e);
    } finally {
      setIsCardDeleting(false);
    }
  };

  const onSuccessAddedCard = () => {
    setEditMode(false);
    fetchPaymentMethods();
    firebaseAddPaymentInfoEvent({ payment_type: 'card', currency: 'USD', value: 0 });
  };

  const stripeElementsOptions: StripeElementsOptions = {
    mode: 'setup',
    currency: 'usd',
    // clientSecret,
  };

  return (
    <div>
      <div className="page-header-title">
        <h3 className="m-b-10">{t('paymentMethodTitle')}</h3>
      </div>
      <div className="task-detail-right">
        <div className="card">
          <div className="card-header d-flex justify-content-between align-items-center">
            <h5>{t('paymentCards')}</h5>
            {canEdit ? (
              <button
                className="btn btn-light-secondary d-inline-flex align-items-center"
                onClick={() => setEditMode(!editMode)}
              >
                <FeatherIcon className="mr-2" icon="credit-card" />
                {editMode ? t('cancel') : t('paymentMethodTab.addNewCard')}
              </button>
            ) : null}
          </div>
          <div className="card-block task-details">
            <div className="alert alert-info mb-5">{t('warnings.no-store-card')}</div>
            {editMode ? (
              <>
                {canEdit && !isLoading && stripePromise ? (
                  <Elements stripe={stripePromise} options={stripeElementsOptions}>
                    <PaymentMethodForm customerId={customerId} onSuccess={onSuccessAddedCard} />
                  </Elements>
                ) : (
                  <div>Loading...</div>
                )}
              </>
            ) : (
              <>
                {paymentCards.length > 0 ? (
                  <div className="row g-4">
                    {paymentCards.map((card) => (
                      <div key={card.id} className="col-12 col-md-6">
                        <BankCard
                          name={card.cardholderName}
                          type={card.type}
                          last4Digits={card.last4Digits}
                          expireDate={card.expiryDate}
                          cardType={card.cardType}
                          onDelete={() => onConfirmDeleteHandler(card.id)}
                          canDelete={canEdit}
                        />
                      </div>
                    ))}
                  </div>
                ) : (
                  <p className="text-muted m-t-0">{t('noCard')}!</p>
                )}
              </>
            )}
          </div>
        </div>
      </div>

      {isOpen ? (
        <ConfirmModal
          isOpen={isOpen}
          title={t('confirmAction')}
          text={t('confirmDeletion')}
          onCancel={() => setIsOpen(false)}
          onConfirm={() => onDeleteCard(selectedCardId)}
          confirmText={isCardDeleting ? 'Deleting...' : 'Yes, delete it'}
          dangerConfirmButton
        />
      ) : null}
    </div>
  );
};
export default PaymentMethodPage;
