interface CardItemDTO {
  id: string;
  type: 'card';
  payment_method: string;
  last_four_digits: string;
  expiry_date: string;
  card_type: 'visa' | 'mastercard';
  cardholder_name: string;
}

export function mapPaymentCards(items: CardItemDTO[]) {
  if (Array.isArray(items) && items.length === 0) {
    return [];
  }

  return items.map((card) => ({
    id: card.id,
    type: card.type,
    paymentMethod: card.payment_method,
    cardType: card.card_type,
    expiryDate: card.expiry_date,
    last4Digits: card.last_four_digits,
    cardholderName: card.cardholder_name,
  }));
}
