/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable @typescript-eslint/no-empty-function */
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Accordion from './Accordion';

interface NavbarProps {
  isOpen?: boolean;
  onOpen?: (c: boolean) => void;
}

const Navbar: React.FC<NavbarProps> = ({ isOpen, onOpen }) => {
  const { t } = useTranslation();

  const [isMobile] = useState(window.innerWidth < 991);

  return (
    <nav className="pcoded-navbar" style={{ marginLeft: !isMobile ? 0 : isOpen && isMobile ? 0 : -264 }}>
      <div className="navbar-wrapper">
        <div className="navbar-brand header-logo">
          <a href="#" className="b-brand">
            <span className="b-title">
              {t('dashboard')}
              <br />
              <span style={{ fontSize: 12 }}>Loop Campaign</span>
            </span>
          </a>
        </div>
        <div className="navbar-content scroll-div">
          <div className="navbar-content_links">
            <Accordion isOpenNav={isOpen!} onOpen={onOpen} />

            {/*   NAV PIN BLOCK */}
            {/*  <ul className="nav pcoded-inner-navbar">
            <li className="nav-item">
              <span className="nav-link nav-link_support">
                <span className="pcoded-micon">
                  <i className="feather icon-help-circle"></i>
                </span>
                <span className="pcoded-mtext">
                  Support PIN: <span>6561</span>
                </span>
                <i className="resetSupportPin feather icon-repeat"></i>
              </span>
            </li>
          </ul> */}
          </div>
        </div>
      </div>
    </nav>
  );
};
export default Navbar;
